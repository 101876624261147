import { post, get } from '@/libs/axios'

export const CreateTemplate = params => post('/template/CreateTemplate', params)
export const UpdateTemplate = params => post('/template/UpdateTemplate', params)
export const DeleteTemplateById = params => post('/template/DeleteTemplateByCodes', params)
export const GetTemplateListByPage = params => post('/template/GetTemplateListByPage', params)
export const GetTemplateById = params => post('/template/GetTemplateDetailsByCode', params)
export const GetTemplateContentByTemplateId = params => post('/template/GetTemplateContentByTemplateId', params)
export const SaveOrUpdateTemplateContent = params => post('/template/SaveOrUpdateTemplateContent', params)
export const SaveOrUpdateTemplate = params => post('/template/SaveOrUpdateTemplate', params)
export const GetAssessTemplateOptions = params => post('/template/GetTemplateList', params)
export const GetOrgTemplateList = () => get('/template/org/GetOrgTemplateList')
